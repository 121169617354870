import React, { useEffect, useState } from 'react';
import { Link, useLocation, matchPath, useNavigate } from 'react-router-dom';
import { CiShoppingCart } from "react-icons/ci";
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { addProdToCart, getUserCart } from '../app/features/user/userSlice';
import { getAProduct, getAllProducts } from '../app/features/products/productSlice';
import { CiHome } from "react-icons/ci";
import { FiPhone } from "react-icons/fi";





const CartAddBar = ({ color, size, quantity, uploadedFiles }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [alreadyAdded, setAlreadyAdded] = useState(false);

  const productState = useSelector(state => state.product.singleProduct);
  
  const getProductId = location.pathname.split('/')[2];
  const cartState = useSelector(state => state.auth.cartProduct);
  console.log(cartState)

  useEffect(() => {
    dispatch(getAProduct(getProductId));
    dispatch(getUserCart());
    dispatch(getAllProducts());
  }, [dispatch, getProductId]);

  useEffect(() => {
    if (Array.isArray(cartState) && cartState.some(item => item?.productId?._id === getProductId)) {
      setAlreadyAdded(true);
    }
  }, [cartState, getProductId]);

  const uploadCart = () => {
    if (productState?.category === 'Clothes' && !size) {
      toast.error('Please Choose Size');
      return;
    }
    if (!color) {
      toast.error('Please Choose Color');
      return;
    }
    dispatch(addProdToCart({
      productId: productState?._id,
      quantity,
      color,
      size,
      uploadedFiles,
      price: productState?.price
    }));
    navigate('/cart');
  };

  

  const isSingleProductPage = matchPath({ path: '/product/:id', end: true }, location.pathname);

  if (!isSingleProductPage) {
    return null;
  }

  return (
    <div className="bb">
      

                <Link to="/" className='d-flex align-items-center'>
                  <div className='addwish-ic d-flex align-items-center gap-10 icon-link b-icon'>
                    <CiHome className='fs-cart2' />
                  </div>
                </Link>

                <div className='d-flex align-items-center b-icon'>
                  <a href="tel:+256 704116349">
                    <FiPhone className='fs-cart3' />
                  </a>
                </div>

                <button 
                  onClick={() => alreadyAdded ? navigate('/cart') : uploadCart()}
                  className={`cart-bar ${alreadyAdded ? '' : 'btn-disabled'}`}
                >
                  <CiShoppingCart className='fs-cart1' />
                  <p>{alreadyAdded ? 'Go to cart' : 'Add to cart'}</p>
                </button>
    </div>
  );
};


export default CartAddBar;
import axios from "axios";
import { base_url } from "../../../utils/axiosConfig";



export const getColors = async () => {
  const response = await axios.get(`${base_url}color`);
  console.log(response.data);
  return response.data;
};




export const colorService = {
  getColors,
  
}
import React from 'react'
import BreadCrumb from '../components/BreadCrumb';
import Meta from '../components/Meta'
import location from '../images/icon/contact-1.png';
import phone from '../images/icon/contact-2.png';
import email from '../images/icon/contact-3.png';
import days from '../images/icon/contact-4.png';
import contactBanner from '../images/banner/cont-banner.jpg';
import logoo from '../images/logo/kupto-final logo-2.png';

import Container from '../components/Container';
import * as yup from 'yup'
import {useFormik} from 'formik'
import {useDispatch} from 'react-redux';
import { createQuery } from '../app/features/contact/contactSlice';



const contactSchema = yup.object({
  name: yup.string().required('Name is Required'),
  email: yup.string().nullable().email('Email should be valid').required('Email is Required'),
  mobile: yup.string().default('').nullable().required('Mobile Number is Required'),
  comment: yup.string().default('').nullable().required('Comment is Required'),

});

const Contact = () => {
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      mobile: '',
      comment: '',
    },
    validationSchema: contactSchema,
    onSubmit: values => {
      dispatch(createQuery(values))
    },
  });
  return (
    <>
    <Meta title='Contact'/>
    <BreadCrumb title='Contact'/>

    <Container class1='contact-wrapper'>
      <div className='contact-row'>
          <div className='col-24 cont-map'>
            <img src={contactBanner} alt='' className='contact-size' />
            <div className='sub-conta'>
              <img src={logoo} alt='' className='logo-cont'/>
            </div>
          </div>

          <div className='col-25'>
            <div className='cont-wrap'>
              <div>
                <h3 className='contact-title'>Contact</h3>
                <form action='' onSubmit={formik.handleSubmit} className='formms'>
                  <div>
                    <input 
                      type='text' 
                      className='form-c' 
                      placeholder='Name'
                      name='name'
                      onChange={formik.handleChange('name')}
                      onBlur={formik.handleBlur('name')}
                      value={formik.values.name}
                      />
                      <div className='error'>
                        {
                          formik.touched.name && formik.errors.name
                        }
                      </div>
                  </div>
                  <div>
                    <input 
                      type='email' 
                      className='form-c' 
                      placeholder='Email'
                      name='email'
                      onChange={formik.handleChange('email')}
                      onBlur={formik.handleBlur('email')}
                      value={formik.values.email}
                      />
                      <div className='error'>
                        {
                          formik.touched.email && formik.errors.email
                        }
                      </div>
                  </div>
                  <div>
                    <input 
                      type='tel' 
                      className='form-c' 
                      placeholder='Mobile Number'
                      name='mobile'
                      onChange={formik.handleChange('mobile')}
                      onBlur={formik.handleBlur('mobile')}
                      value={formik.values.mobile}
                      />
                      <div className='error'>
                        {
                          formik.touched.mobile && formik.errors.mobile
                        }
                      </div>
                  </div>
                  <div>
                    <textarea 
                      id='' 
                      className='w-100 text-b' 
                      cols='30' 
                      rows='5'
                      placeholder='Comments'
                      name='comment'
                      onChange={formik.handleChange('comment')}
                      onBlur={formik.handleBlur('comment')}
                      value={formik.values.comment}
                    ></textarea>
                    <div className='error'>
                        {
                          formik.touched.comment && formik.errors.comment
                        }
                      </div>
                  </div>
                    <div>
                      <button className='button-cont' type='submit'>Submit</button>
                    </div>
                </form>
                
              </div>

              <div>
                <h3 className='contact-title'>Get in touch with us</h3>
                <div>
                  <ul className='ps-0'>
                    <li className='list-cont'><img src={location} alt=''width='28px'/>
                    <address className='addre'>P.O Box 9696 Nasser Road, Light Arcade, Room no. B14</address>
                    </li>
                    <li className='list-cont'><img src={phone} alt=''width='28px'/>
                    <a href='tel:+256 704116349' className='addre'>+256 704116349</a>
                    </li>
                    <li className='list-cont'><img src={email} alt=''width='28px'/>
                    <a href='mailto:kupto2020@gmail.com' className='addre'>kupto2020@gmail.com</a>
                    </li>
                    <li className='list-cont'><img src={days} alt=''width='28px'/>
                    <p className='addre'>Monday-Saturday 8:00 AM - 7:00 AM</p>
                    </li>
                  </ul>
                </div>
              </div>
              
            </div>
            
          </div>
        </div>
    </Container>

    
    </>
  )
}

export default Contact
import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import { authService } from "./userService";
import { toast } from "react-toastify";

// Async thunks for handling different actions
export const registerUser = createAsyncThunk('auth/register', async (userData, thunkAPI) => {
  try {
    const response = await authService.register(userData);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response?.data || error.message);
  }
});

export const loginUser = createAsyncThunk('auth/login', async (userData, thunkAPI) => {
  try {
    const response = await authService.login(userData);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response?.data || error.message);
  }
});

export const getUserProductWishlist = createAsyncThunk('user/wishlist', async (_, thunkAPI) => {
  try {
    const response = await authService.getUserWishlist();
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response?.data || error.message);
  }
});

export const addProdToCart = createAsyncThunk('user/cart/add', async (cartData, thunkAPI) => {
  try {
    const response = await authService.addToCart(cartData);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response?.data || error.message);
  }
});

export const getUserCart = createAsyncThunk('user/cart/get', async (_, thunkAPI) => {
  try {
    const response = await authService.getCart();
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response?.data || 'Failed to fetch cart');
  }
});

export const deleteUserCart = createAsyncThunk('user/cart/delete', async (_, thunkAPI) => {
  try {
    const response = await authService.emptyCart();
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response?.data || error.message);
  }
});

export const getOrders = createAsyncThunk('user/order/get', async (_, thunkAPI) => {
  try {
    const response = await authService.getUserOrders();
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response?.data || error.message);
  }
});

export const deleteCartProduct = createAsyncThunk('user/cart/product/delete', async (id, thunkAPI) => {
  try {
    const response = await authService.removeProductFromCart(id);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response?.data || error.message);
  }
});

export const updateCartProduct = createAsyncThunk('user/cart/product/update', async (cartDetail, thunkAPI) => {
  try {
    const response = await authService.updateProductFromCart(cartDetail);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response?.data || error.message);
  }
});

export const updateProfile = createAsyncThunk('user/profile/update', async (data, thunkAPI) => {
  try {
    const response = await authService.updateUser(data);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response?.data || error.message);
  }
});

export const forgotPasswordTok = createAsyncThunk('user/password/token', async (data, thunkAPI) => {
  try {
    const response = await authService.forgotPassToken(data);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response?.data || error.message);
  }
});

export const resetPassword = createAsyncThunk('user/password/reset', async (data, thunkAPI) => {
  try {
    const response = await authService.resetPass(data);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response?.data || error.message);
  }
});

export const createAnOrder = createAsyncThunk('user/cart/create-order', async (data, thunkAPI) => {
  try {
    const response = await authService.createOrder(data);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response?.data || error.message);
  }
});

export const resetState = createAction('Reset_all');

// Initial state
const getCustomerfromLocalStorage = localStorage.getItem('customer') ? JSON.parse(localStorage.getItem('customer')) : null;

const initialState = {
  user: getCustomerfromLocalStorage,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
  cartProduct: [],
  wishlist: [],
  createdUser: null,
  updatedUser: null,
  deletedCartProduct: null,
  createdOrder: null,
  token: null,
  pass: null,
};

// Reducer slice
const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Register user actions
      .addCase(registerUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.createdUser = action.payload;
        toast.info('User Created Successfully');
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
        toast.error(state.message);
      })
      // Login user actions
      .addCase(loginUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.user = action.payload;
        localStorage.setItem('token', action.payload.token);
        toast.info('User Logged in Successfully');

        // After login, fetch the user's cart
        // Dispatch the getUserCart action to update the cart immediately
        state.cartProduct = action.payload.cart || [];  // assuming the cart is part of the response
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
        toast.error(state.message);
      })
      // Get user wishlist
      .addCase(getUserProductWishlist.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUserProductWishlist.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.wishlist = action.payload;
      })
      .addCase(getUserProductWishlist.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      // Add product to cart
      .addCase(addProdToCart.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addProdToCart.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.cartProduct = action.payload;
        toast.success('Product Added to Cart');
      })
      .addCase(addProdToCart.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
        toast.error(state.message);
      })
      // Other actions (e.g., delete product, update product, etc.)
      .addCase(getUserCart.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUserCart.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        // Ensure cartProduct is always an array
        state.cartProduct = Array.isArray(action.payload) ? action.payload : [];
      })
      .addCase(getUserCart.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      // Additional actions like deleteCartProduct, updateCartProduct, etc.
      .addCase(deleteCartProduct.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteCartProduct.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.deletedCartProduct = action.payload;
        toast.success('Product Deleted Successfully!');
      })
      .addCase(deleteCartProduct.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
        toast.error(state.message);
      })
      // Reset state
      .addCase(resetState, () => initialState);
  }
});

export default authSlice.reducer;
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { postService } from "./postService";

export const getAllPosts = createAsyncThunk('post/get', async (thunkAPI) => {
  try {
    return await postService.getPosts();
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response?.data || error.message);
  }
});

const postState = {
  post: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
}

export const postSlice = createSlice({
  name: 'post',
  initialState: postState,
  reducers: {},
  extraReducers: (builder) => {
    builder
    .addCase(getAllPosts.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(getAllPosts.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.post = action.payload; // Ensure action.payload contains the expected data
    })
    .addCase(getAllPosts.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.message = action.payload || action.error.message; // Properly handle error messages
    })
      
  }
});

export default postSlice.reducer;
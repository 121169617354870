/*export const base_url = "https://kupto.herokuapp.com/api";

const getTokenFromLocalStorage = localStorage.getItem('customer')
  ? JSON.parse(localStorage.getItem('customer'))
  : null;

console.log('Retrieved token from local storage:', getTokenFromLocalStorage);

export const config = {
  headers: {
    'Accept': 'application/json',
    'Authorization': `Bearer ${getTokenFromLocalStorage ? getTokenFromLocalStorage.token : ''}`,
  },
};

console.log('Request headers configuration:', config);

*/
export const base_url = "https://kupto-0a0835a1dfe5.herokuapp.com/api/";


// Safely get the token from local storage
let getTokenFromLocalStorage;
try {
  getTokenFromLocalStorage = localStorage.getItem('customer') 
    ? JSON.parse(localStorage.getItem('customer')) 
    : null;
  
} catch (error) {
  console.error('Error retrieving token from local storage:', error);
  getTokenFromLocalStorage = null;
}

// Set up the config with headers
export const config = {
  headers: {
    'Accept': 'application/json',
    ...(getTokenFromLocalStorage && getTokenFromLocalStorage.token 
      ? { 'Authorization': `Bearer ${getTokenFromLocalStorage.token}` } 
      : {}),
  },
};



import React, { useEffect } from 'react';
import BreadCrumb from '../components/BreadCrumb';
import Meta from '../components/Meta';
import cross from '../images/icon/cross.png';
import Container from '../components/Container';
import { useDispatch, useSelector } from 'react-redux';
import { getUserProductWishlist } from '../app/features/user/userSlice';
import { addToWishlists } from '../app/features/products/productSlice';

const Wishlist = () => {
  const dispatch = useDispatch();

  // Fetch wishlist when the component mounts
  useEffect(() => {
    dispatch(getUserProductWishlist());
  }, [dispatch]); // Only re-run if dispatch changes

  const wishlistState = useSelector((state) => state.auth.wishlist?.wishlist);

  const removeFromWishlist = (id) => {
    dispatch(addToWishlists(id));
    setTimeout(() => {
      dispatch(getUserProductWishlist());
    }, 300);
  };

  return (
    <>
      <Meta title='Wishlist' />
      <BreadCrumb title='Wishlist' />
      <Container class1='wishlist-wrapper'>
        <div className='col-26'>
          {Array.isArray(wishlistState) && wishlistState.length > 0 ? (
            wishlistState.map((item, index) => (
              <div className='wishlist-card position-relative' key={index}>
                <img
                  onClick={() => removeFromWishlist(item?._id)}
                  src={cross}
                  alt='Remove item'
                  width='20px'
                  className='position-absolute cross'
                />
                <div className='wishlist-card-image'>
                  <img
                    src={item?.images[0]?.url}
                    alt={item?.title}
                    className='w-100 prod-image d-block mx-auto'
                    width={160}
                  />
                </div>
                <div className='wishlist-description'>
                  <p className='brand'>{item?.title}</p>
                  <p className='price-styleet'>
                  UGX <strong> {item?.price}</strong>
                  </p>
                </div>
              </div>
            ))
          ) : (
            <p>Your wishlist is empty.</p>
          )}
        </div>
      </Container>
    </>
  );
};

export default Wishlist;
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { colorService } from "./colorService";

export const getAllColors = createAsyncThunk('color/get', async (_, thunkAPI) => {
  try {
    return await colorService.getColors();
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response?.data || error.message);
  }
});

const colorState = {
  colors: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
}

export const colorSlice = createSlice({
  name: 'colors',
  initialState: colorState,
  reducers: {},
  extraReducers: (builder) => {
    builder
    .addCase(getAllColors.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(getAllColors.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.colors = action.payload; // Ensure action.payload contains the expected data
      
    })
    .addCase(getAllColors.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.message = action.payload || action.error.message; // Properly handle error messages
    })
      
  }
});

export default colorSlice.reducer;
export const selectColors = (state) => state.color.colors || [];
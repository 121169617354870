import React, { useEffect, useMemo } from 'react';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import ReactStars from 'react-rating-stars-component';
import { useDispatch, useSelector } from 'react-redux';
import { getAllBrands } from '../app/features/brand/brandSlice';
import { FaRegHeart } from "react-icons/fa";
import { toast } from 'react-toastify';
import { addToWishlists } from '../app/features/products/productSlice';

const ProductCard = (props) => {
  const { grid, data } = props;
  let location = useLocation();
  const products = Array.isArray(data) ? data : [];
  const { brands } = useSelector((state) => state.brand);
  const authState = useSelector(state => state?.auth);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => { 
    dispatch(getAllBrands());
  }, [dispatch]);

  const brandMap = useMemo(() => {
    return brands.reduce((acc, brand) => {
      acc[brand._id] = brand.title;
      return acc;
    }, {});
  }, [brands]);

  const getStarSize = () => {
    const width = window.innerWidth;
    if (width <= 360) {
      return 10; 
    } else if (width <= 768) {
      return 13;
    } else {
      return 14;
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setStarSize(getStarSize());
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const [starSize, setStarSize] = React.useState(getStarSize());

  const addToWish = (id) => {
    if (!authState?.user) {
      // If user is not authenticated, redirect to login page
      toast.info('Please log in to add to wishlist');
      navigate('/login');
    } else {
      // Proceed with adding to wishlist if the user is authenticated
      dispatch(addToWishlists(id))
        .then(() => toast.success('Product added to wishlist!'))
        .catch((error) => toast.error(`Failed to add product to wishlist: ${error.message}`));
    }
  };

  return (
    <>
      {products.map((item, index) => (
        <div
          key={index}
          className={`${location.pathname === '/product' ? `gr-${grid}` : 'col-4'}`}
        >
          <div className="product-card">
            {/* Wishlist Icon (Outside the Link) */}
            <div 
              onClick={(e) => {
                e.stopPropagation();  // Prevent navigation when wishlist icon is clicked
                addToWish(item?._id);  // Add product to wishlist
              }} 
              className="wish">
              <FaRegHeart className="wish-c"/>
            </div>

            {/* Product Link (Wraps the product details except the wishlist icon) */}
            <Link to={`/product/${item?._id}`} className="product-link">
              {/* Product Image and Card Content */}
              <div className="product-image">
                <img src={item?.images[0]?.url} alt='' className="prod-img" />
              </div>

              {/* Product Details */}
              <div className="product-details">
                <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                  <ReactStars 
                    count={5}
                    size={starSize}
                    value={Number(item?.totalrating)}
                    edit={false}
                    activeColor='#ffdf00'
                  />
                </div>

                <p className="brand mb-5">{item?.title}</p>

                <p
                  className={`descript ${grid === 12 ? 'd-block' : 'd-none'}`}
                  dangerouslySetInnerHTML={{ __html: item?.description }}
                />

                <p className="price-styleet">UGX <strong>{item?.price}</strong></p>
              </div>
            </Link>
          </div>
        </div>
      ))}
    </>
  );
};

export default ProductCard;
import React, { useEffect } from 'react';

function Colors(props) {
  const { colorData, selectedColor, setColor } = props;

  console.log('Selected Colors:', selectedColor); // Log selected colors

  const handleColorClick = (id) => {
    setColor(id); // Trigger the parent setColor function to update the selected color
  };

  return (
    <ul className='colors mt-10'>
      {colorData && colorData.map((item, index) => {
        const isSelected = selectedColor.includes(item?._id);

        return (
          <div className={`color-border ${isSelected ? 'selected' : ''}`} key={index}>
            <li
              className='color-des'
              onClick={() => handleColorClick(item?._id)}
              style={{ backgroundColor: item?.title }}
            />
          </div>
        );
      })}
    </ul>
  );
}

export default Colors;
import React, { useEffect, useState, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import BreadCrumb from '../components/BreadCrumb';
import { MdSkipPrevious, MdSkipNext } from "react-icons/md";
import Meta from '../components/Meta';
import Container from '../components/Container';
import ProductCard from '../components/ProductCard';
import { useDispatch, useSelector } from 'react-redux';
import { filterProducts, getAllProducts, setFilteredProducts } from '../app/features/products/productSlice';
import { selectCategories } from '../app/features/category/categorySlice';
import { getAllBrands, selectBrands } from '../app/features/brand/brandSlice';
import { getAllColors } from '../app/features/color/colorSlice';
import Colors from '../components/Colors';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const OurStore = () => {
  const [grid, setGrid] = useState(3);
  const [brands, setBrands] = useState([]);
  const [tags, setTags] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const productsPerPage = 15;

  const categories = useSelector(selectCategories);
  const query = useQuery();
  const categoryQuery = query.get('category');

  const [filters, setFilters] = useState({
    tag: null,
    category: categoryQuery || null,
    brand: null,
    minPrice: null,
    maxPrice: null,
    sort: null,
    color: [], // Add color filter here
  });

  const dispatch = useDispatch();
  const productState = useSelector((state) => state.product.filteredProducts || []);
  const allProducts = useSelector((state) => state.product.products || []);
  const searchTerm = useSelector((state) => state.product.searchTerm);
  const brandsData = useSelector(selectBrands);
  const { colors } = useSelector((state) => state.color);
  const [loadingColors, setLoadingColors] = useState(true);

  useEffect(() => {
    if (allProducts.length === 0) dispatch(getAllProducts());
    if (brandsData.length === 0) dispatch(getAllBrands());
    if (colors.length === 0) dispatch(getAllColors());
    if (colors.length > 0) setLoadingColors(false);
  }, [dispatch, allProducts.length, brandsData.length, colors.length]);

  const brandMap = useMemo(() => {
    const map = {};
    brandsData.forEach(brand => {
      map[brand._id] = brand.title;
    });
    return map;
  }, [brandsData]);

 /* const handleColorSelection = (colorId) => {
    setFilters((prev) => {
      const newColors = prev.color ? [...prev.color] : [];
      if (newColors.includes(colorId)) {
        return { ...prev, color: newColors.filter((c) => c !== colorId) }; // Deselect color if already selected
      } else {
        return { ...prev, color: [...newColors, colorId] }; // Add color to selection
      }
    });
    console.log('Updated Filters:', filters); // Log to check if the color is being added/removed correctly
  };*/
  const handleColorSelection = (colorId) => {
    setFilters((prevFilters) => {
      const newColorSelection = prevFilters.color.includes(colorId)
        ? prevFilters.color.filter((color) => color !== colorId) // Remove color if already selected
        : [...prevFilters.color, colorId]; // Add color if not selected
  
      return {
        ...prevFilters,
        color: newColorSelection, // Update the color filter
      };
    });
    console.log('Updated Filters:', filters);
  };
  

  
  useEffect(() => {
    // Check if any filter has been set (e.g., color, brand, price, etc.)
    const shouldFilter = searchTerm || filters.color.length > 0 || filters.category || filters.brand || filters.minPrice || filters.maxPrice || filters.sort;
  
    if (shouldFilter) {
      // Dispatch the filter action with all active filters
      dispatch(filterProducts({ ...filters, searchTerm }));
    } else {
      // If no filters are active, reset to show all products
      dispatch(setFilteredProducts(allProducts));
    }
  
    setCurrentPage(1); // Reset pagination to page 1 when filters change
  }, [filters, searchTerm, allProducts, dispatch]);

  useEffect(() => {
    const uniqueBrands = [...new Set(allProducts.map(product => product.brand))];
    const uniqueTags = [...new Set(allProducts.flatMap(product => product.tags || []))];

    setBrands(uniqueBrands);
    setTags(uniqueTags);
  }, [allProducts]);

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = useMemo(() => productState.slice(indexOfFirstProduct, indexOfLastProduct), [productState, indexOfFirstProduct, indexOfLastProduct]);

  const totalPages = useMemo(() => Math.ceil(productState.length / productsPerPage), [productState, productsPerPage]);

  const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);
  const handleNextClick = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousClick = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const renderPageNumbers = useMemo(() => {
    return Array.from({ length: totalPages }, (_, i) => (
      <li
        key={i + 1}
        onClick={() => handlePageChange(i + 1)}
        className={currentPage === i + 1 ? 'active' : ''}
        style={{ display: 'inline', cursor: 'pointer', padding: '5px 10px', border: currentPage === i + 1 ? '1px solid black' : '1px solid #ddd', borderRadius: '4px', margin: '2px' }}
      >
        {i + 1}
      </li>
    ));
  }, [currentPage, totalPages]);

  

  console.log(filters.color); // Check if the colors are being updated correctly



  

  return (
    <>
      <Meta title='Our Store'/>
      <BreadCrumb title='Our Store'/>
      {console.log('Current filtered products:', productState)}

      <Container class1='store-wrapper'>
        <div className='d-flex gap-10 flex-1'>
          <div className='col-21'>
            <div className='filter-card'>
              <h3 className='filter-title'>Shop By Category</h3>
              <div>
                <ul>
                  {categories.map((item, index) => (
                    <li key={index} onClick={() => setFilters(prev => ({ ...prev, category: item._id }))}>
                      {item.title}
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <div className='filter-card'>
              <h3 className='filter-title'>Filter By</h3>
              <div>
                <h5 className='sub-title'>Price</h5>
                <div className='price-form'>
                  <div className="form-floating">
                    <input 
                      type="number" 
                      className="form-control-2" 
                      id="floatingInput" 
                      placeholder="From"
                      onChange={(e) => setFilters(prev => ({ ...prev, minPrice: e.target.value }))}
                    />
                  </div>
                  <div className="form-floating">
                    <input 
                      type="number" 
                      className="form-control-2" 
                      id="floatingInput1" 
                      placeholder="To"
                      onChange={(e) => setFilters(prev => ({ ...prev, maxPrice: e.target.value }))}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className='filter-card'>
              <h3 className='filter-title'>Product Brands</h3>
              <div className='product-tags'>
              {brandsData.map((item, index) => (
                <span 
                  key={item._id} // Use the brand ID as the key for uniqueness
                  onClick={() => setFilters(prev => ({ ...prev, brand: prev.brand === item._id ? null : item._id }))} // Use the brand ID for filtering
                  className={filters.brand === item._id ? 'badge-span active' : 'badge-span'}
                >
                  {item.title} {/* Display the brand title */}
                </span>
              ))}


              </div>
            </div>

            <div className='filter-card'>
              <h3 className='filter-title'>Product Colors</h3>
              <div className='product-tags'>
              <Colors 
                  colorData={colors} 
                  selectedColor={filters.color} // Pass selected colors from the parent
                  setColor={handleColorSelection} // Pass the parent function to update selected colors
                />
              </div>
            </div>
          </div>

          <div className='col-22'>
            <div className='filter-sort-grid'>
              <div className='sort-grid-dis'>
                <div className='filter-display'>
                  <p>Sort By:</p>
                  <select 
                    className='form-contr'
                    onChange={(e) => setFilters(prev => ({ ...prev, sort: e.target.value }))}
                  >
                    <option value='title'>Alphabetically, A-Z</option>
                    <option value='-title'>Alphabetically Z-A</option>
                    <option value='price'>Price, low to high</option>
                    <option value='-price'>Price, high to low</option>
                    <option value='createdAt'>Date, old to new</option>
                    <option value='-createdAt'>Date, new fucknope to old</option>
                  </select>
                </div>
                <div className='right-dis'>
                  <p className='totalproducts'>Products</p>
                  <div className='right-dis'>
                    <img 
                      onClick={() => setGrid(3)} 
                      src='images/icon/bar-1.png' 
                      alt='grid' 
                      className='bar-icon dark' 
                    />
                    <img 
                      onClick={() => setGrid(4)} 
                      src='images/icon/bar-2.png' 
                      alt='grid' 
                      className='bar-icon' 
                    />
                    <img 
                      onClick={() => setGrid(6)} 
                      src='images/icon/bar-3.png' 
                      alt='grid' 
                      className='bar-icon' 
                    />
                    <img 
                     
                      src='images/icon/bar-4.png' 
                      alt='grid' 
                      className='bar-icon' 
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='products-list'>
              <ProductCard data={currentProducts} grid={grid} />
            </div>
            <div className='pagin-des mb-40'>
              <ul className="pagination" style={{ listStyle: 'none', padding: 0, display: 'flex', justifyContent: 'center' }}>
                <li
                  onClick={handlePreviousClick}
                  className={currentPage === 1 ? 'disabled' : ''}
                >
                  <MdSkipPrevious className='pagin-icon' />

                </li>
                {renderPageNumbers}
                <li
                  onClick={handleNextClick}
                  className={currentPage === totalPages ? 'disabled' : ''}
                >
                  <MdSkipNext className='pagin-icon'/>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default OurStore;
import axios from "axios";
import { base_url } from "../../../utils/axiosConfig";


/*
const getPosters = async()=>{
  const response = await axios.get(`${base_url}poster/`, );
  
  return response.data;
};*/

const getPosters = async() => {
  try {
    const response = await axios.get(`${base_url}poster/`);
   
    return response.data;
  } catch (error) {
    console.error('Error fetching posters:', error); // Log any errors
    throw error;
  }
};








export const posterService = {
  getPosters,
  
}
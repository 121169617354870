import React, { useEffect, useMemo, useState } from 'react';
import BreadCrumb from '../components/BreadCrumb';
import Meta from '../components/Meta';
import { RiDeleteBin6Line } from "react-icons/ri";
import { Link } from 'react-router-dom';
import Container from '../components/Container';
import { useDispatch, useSelector } from 'react-redux';
import { getUserCart, deleteCartProduct, updateCartProduct, resetState } from '../app/features/user/userSlice';
import { IoMdClose } from "react-icons/io";
import { toast } from 'react-toastify';
import Dropzone from 'react-dropzone';
import { deleteAFile, uploadAFile } from '../app/features/upload/uploadSlice';




const Cart = () => {
  const dispatch = useDispatch();

  const userCartState = useSelector(state => state?.auth?.cartProduct);
  console.log('Product Tags:', userCartState[0]?.productId?.tags);
  const [productUpdateDetail, setProductUpdateDetail] = useState(null);

  //const [productUpdateDetail, setProductUpdateDetail] = useState(null);
  const [totalAmount, setTotalAmount] = useState(0);
  const [price, setPrice] = useState(0);
  const [designPrice, setDesignPrice] = useState(0);
  const [isDesignSelected, setIsDesignSelected] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [quantity, setQuantity] = useState(1);


  useEffect(() => {
    dispatch(getUserCart());
  }, [dispatch]);

 

  useEffect(() => {
    if (productUpdateDetail?.cartItemId && productUpdateDetail?.quantity) {
      dispatch(updateCartProduct(productUpdateDetail)).then(() => {
        dispatch(getUserCart());
        setProductUpdateDetail(null); // Clear after update
      });
    }
  }, [productUpdateDetail, dispatch]);

  

  const deleteACartProduct = async (id) => {
    try {
      await dispatch(deleteCartProduct(id)).unwrap();
      const cartData = await dispatch(getUserCart()).unwrap();
      // Handle the cartData here
    } catch (error) {
      console.error("Error updating cart:", error);
      // Handle the error, maybe show a toast notification
    }
  };



  useEffect(() => {
    let sum = 0;
    if (Array.isArray(userCartState)) {
      userCartState.forEach(item => {
        sum += Number(item.quantity) * item.price;
      });
    }
    setTotalAmount(sum);
  }, [userCartState]);

  const handleQuantityChange = (item, newQuantity) => {
    const quantity = Number(newQuantity);
    
    // Optimistically update the total amount
    const updatedCart = userCartState.map(cartItem => {
      if (cartItem._id === item._id) {
        return { ...cartItem, quantity };
      }
      return cartItem;
    });
  
    let sum = 0;
    updatedCart.forEach(cartItem => {
      sum += cartItem.price * cartItem.quantity;
    });
    
    setTotalAmount(sum);
    setProductUpdateDetail({ cartItemId: item._id, quantity });
  };

  const handleDeleteFile = async (public_id) => {
    try {
      const result = await dispatch(deleteAFile(public_id)).unwrap();
      
      if (result) {
        // Update the uploadedFiles state to remove the deleted file
        setUploadedFiles((files) => files.filter((file) => file.public_id !== public_id));
        toast.success('File deleted successfully');
      } else {
        throw new Error('Failed to delete file');
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message || 'An unexpected error occurred';
      console.error('Error during file deletion:', errorMessage);
      toast.error(errorMessage);
    }
  };

  const handleFileDrop = (acceptedFiles) => {
    setUploading(true);
    dispatch(uploadAFile(acceptedFiles))
      .unwrap()
      .then((uploadedFiles) => { // Adjusted the variable name
        setUploading(false);
        if (Array.isArray(uploadedFiles)) {
          setUploadedFiles(prevFiles => [...prevFiles, ...uploadedFiles]); // Add uploaded files to state
          toast.success('File uploaded successfully');
        } else {
          console.error('Unexpected response format:', uploadedFiles);
          toast.error('Unexpected response format from file upload');
        }
      })
      .catch((error) => {
        setUploading(false);
        toast.error('Failed to upload File');
        console.error('Error uploading File:', error);
      });
  };
  

  const effectivePrice = useMemo(() => {
    if (!userCartState) {
        return 0; // Default value if userCartState is not available
    }

    // Use userCartState price directly, without considering selectedSize
    const basePrice = userCartState.price; 
    const totalDesignPrice = isDesignSelected ? designPrice : 0; 
    const printingPrice = userCartState?.printingPrice || 0;

    // Start with the base price
    let totalPrice = basePrice + totalDesignPrice;

    // Add printing price if files are uploaded
    if (uploadedFiles.length > 0) {
        totalPrice += printingPrice;
    }

    // Apply discount for bulk orders (500 or more)
    if (quantity >= 500) {
        const discount = 0.1; // Example: 10% discount
        totalPrice *= (1 - discount);
    }

    // Return the total price
    return totalPrice; 
}, [quantity, userCartState, isDesignSelected, designPrice, uploadedFiles]);

  return (
    <>
      <Meta title="Cart" />
      <BreadCrumb title="My Cart" />
      <Container class1="cart-wrapper">
        <div className='section-11'>
          <div className="cart-header d-flex align-items-center justify-content-between mb-10">
            <h3 className="cart-col-1">PRODUCT</h3>
            <h3 className="cart-col-2">PRICE</h3>
            <h3 className="cart-col-3">COUNT</h3>
            <h3 className="cart-col-4">TOTAL</h3>
          </div>
          {Array.isArray(userCartState) && userCartState.map((item, index) => (
          <div key={index} className="cart-data d-flex align-items-center justify-content-between">
            <div className="cart-col-1 d-flex align-items-center gap-10">
              <div className="cart-dis">
                <img src={item?.productId?.images[0]?.url} alt="" className="w-100" />
              </div>
              <div className="cart-des">
                <p>{item?.productId?.title}</p>
                <div className="d-flex gap-5 align-items-center">
                  <p>Color:</p>
                  <ul className="colors">
                    <li style={{ backgroundColor: item?.color?.title }}></li>
                  </ul>
                </div>
                <p className='d-flex gap-5 align-items-center'>
                  Size:
                  <span>{item?.size?.title}</span>
                </p>
              </div>
            </div>
            <div className="cart-col-2">
              <p className="price-style"><strong>{item?.price}</strong>/=</p>
            </div>
            <div className="cart-col-3 d-flex align-items-center gap-10">
              <input
                className='sizes number-pro'
                type="number"
                min={1}
                onChange={(e) => handleQuantityChange(item, e.target.value)}
                value={productUpdateDetail?.cartItemId === item._id ? productUpdateDetail.quantity : item.quantity}
              />
              <div className='butt-ddele'>
                <RiDeleteBin6Line
                  onClick={() => deleteACartProduct(item?._id)}
                  className="dele-cart text-danger"
                />
              </div>
            </div>
            <div className="cart-col-4">
              <p className="price-style"><strong>{item?.price * item?.quantity}</strong>/=</p>
            </div>
          </div>
        ))}
          {userCartState.some(product => product?.productId?.tags?.includes('Digital & Offset Printing')) && (
          <div>
            <h3 className='mt-10'>Select Design if you don't have one</h3>
            <div className='d-flex gap-10'>
              <div 
                className={`design-item mt-10 ${isDesignSelected ? 'selected' : ''}`}
                onClick={() => {
                  setDesignPrice(userCartState.find(product => product?.productId?.tags?.includes('Digital & Offset Printing'))?.productId?.design);
                  setIsDesignSelected(true);
                }}
              >
                Design (+ UGX {userCartState.find(product => product?.productId?.tags?.includes('Digital & Offset Printing'))?.productId?.design})
              </div>
              <div 
                className={`design-item mt-10 ${!isDesignSelected ? 'selected' : ''}`}
                onClick={() => {
                  setDesignPrice(0);
                  setIsDesignSelected(false);
                }}
              >
                I have One
              </div>
            </div>

            <div className='border-bottom mt-10'>
              <h3 className='font-tt'>Upload File</h3>
              <div className='upload border-1 bg-white text-center p-5'>
                <Dropzone onDrop={handleFileDrop}>
                  {({ getRootProps, getInputProps }) => (
                    <section>
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <p>
                          {uploading
                            ? 'Uploading...'
                            : "Drag 'n' drop some files here, or click to select files"}
                        </p>
                      </div>
                    </section>
                  )}
                </Dropzone>
              </div>
              <p className='font-13 mb-10 c-gre'>
                The maximum file size is 30 MB (PDF, DOC, EPS, SVG)
              </p>

              <div className='showfiles'>
                {uploadedFiles.map((file, index) => (
                  <div className='pos-relative' key={index}>
                    <p className='mbt'>{file.fileName}</p>
                    <button
                      type='button'
                      onClick={() => handleDeleteFile(file.public_id)}
                      className='btn-close'
                      style={{ position: 'absolute', top: '3px', bottom: '3px', right: '10px', zIndex: 10 }}
                    >
                      <IoMdClose className='font-iccc' />
                    </button>
                  </div>
                ))}
              </div>
              <div>
                <p className='c-r'>Printing on it costs Ugx {userCartState.find(product => product?.productId?.tags?.includes('Digital & Offset Printing'))?.productId?.printingPrice}</p>
              </div>
            </div>
          </div>
        )}
          <div className="pad-cart d-flex justify-content-between align-items-center">
            <Link to="/product" className="button">Continue Shopping</Link>
            {totalAmount > 0 && (
              <div className="d-flex flex-column align-items-end">
                <h3 className="fo-cart mb-10">SubTotal: {totalAmount}/=</h3>
                <p className='cart-foo'>Shipping (Delivery) calculated at checkout</p>
                <Link to="/checkout" className="button check-size mt-10">Checkout</Link>
              </div>
            )}
          </div>
        </div>
      </Container>

      <Container class1="cart-wrapper-mobile">
        <div className='section-cart'>
          
          {Array.isArray(userCartState) && userCartState?.map((item, index) => (
            <div key={index} className="cart-datta">
              <div className="cart-col-1">
                <div className="cart-dis">
                  <img src={item?.productId?.images[0]?.url} alt="" className="w-100" />
                </div>

              </div>

            <div className='col-cart'>
              <div className='d-flex justify-content-between align-items-center'>
                  <div className="cart-des">
                      <p>Product: {item?.productId?.title}</p>

                      <div className='d-flex gap-15 align-items-center'>
                      <div className="d-flex gap-5 align-items-center">
                        <span>Color:</span>
                        <ul className="colors">
                          <li style={{ backgroundColor: item?.color?.title }}></li>
                        </ul>
                      </div>
                      <p className='d-flex gap-5 align-items-center'>
                        Size:
                        <span>{item?.size?.title}</span>
                      </p>
                      
                       
                      
                      
                      </div>
                      

                      
                    </div>
                    <div className='butt-ddele'>
                      <RiDeleteBin6Line
                        onClick={() => deleteACartProduct(item?._id)}
                        className="dele-cart text-danger"
                      />
                    </div>
                  </div>

                <div className='d-flex justify-content-between align-items-center'>
                <div className="cartt-col-3 d-flex align-items-center">
                  
                <input
                  className='sizes number-pro'
                  type="number"
                  min={1}
                  onChange={(e) => {
                    const quantity = e.target.value;
                    console.log("Updating quantity:", quantity);
                    setProductUpdateDetail({ cartItemId: item?._id, quantity });
                  }}
                  value={productUpdateDetail?.quantity ? productUpdateDetail.quantity : item?.quantity}
                />
                  
                  
                </div>
                <div className="cartt-col-4">
                  <p className="price-stylee">UGX <strong>{item?.price * item?.quantity}</strong></p>
                </div>
              </div>
           </div>

            </div>
          ))}
          <div className="pad-cart d-flex justify-content-between align-items-center">
            <Link to="/product" className="button">Continue Shopping</Link>
            {totalAmount > 0 && (
              <div className="d-flex flex-column align-items-end">
                <h3 className="fo-cart mb-10">SubTotal: UGX {totalAmount}</h3>
                <p className='cart-foo'>Shipping (Delivery) calculated at checkout</p>
                <Link to="/checkout" className="button check-size mt-10">Checkout</Link>
              </div>
            )}
          </div>
        </div>
      </Container>
    </>
  );
};

export default Cart;
import React, { useEffect, useState } from 'react';
import Meta from '../components/Meta';
import Container from '../components/Container';
import { useDispatch, useSelector } from 'react-redux';
import Custominput from '../components/Custominput';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { registerUser } from '../app/features/user/userSlice';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

const signUpSchema = yup.object({
  firstname: yup.string().required('First Name is Required'),
  lastname: yup.string().required('Last Name is Required'),
  email: yup.string().email('Email should be valid').required('Email Address is required'),
  mobile: yup.string().required('Mobile No. is Required'),
  password: yup.string().required('Password is Required'),
});

const Signup = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authState = useSelector((state) => state.auth);
  
  // Local state for error message
  const [errorMessage, setErrorMessage] = useState('');

  const formik = useFormik({
    initialValues: {
      firstname: '',
      lastname: '',
      email: '',
      mobile: '',
      password: '',
    },
    validationSchema: signUpSchema,
    onSubmit: (values) => {
      dispatch(registerUser(values));
    },
  });

  useEffect(() => {
    if (authState.createdUser && !authState.isError) {
      navigate('/');
    }

    // Handle error messages from failed sign-ups
    if (authState.isError) {
      setErrorMessage('Wrong credentials, please try again.');
    }
  }, [authState, navigate]);

  return (
    <>
      <Meta title="Sign Up" />
      
      <Container class1="login-wrapper">
        <div className="col-27">
          <div className="auth-card">
            <h3>Create an Account</h3>
            <form onSubmit={formik.handleSubmit} className="formms-log">
              
              {/* Error Message */}
              {errorMessage && <div className="error-message">{errorMessage}</div>}
              
              <Custominput 
                type="text" 
                name="firstname" 
                placeholder="First Name"
                onChange={formik.handleChange}
                value={formik.values.firstname}
                onBlur={formik.handleBlur}
              />
              <div className='error'>
                {formik.touched.firstname && formik.errors.firstname}
              </div>
              
              <Custominput 
                type="text" 
                name="lastname" 
                placeholder="Last Name"
                onChange={formik.handleChange}
                value={formik.values.lastname}
                onBlur={formik.handleBlur}
              />
              <div className='error'>
                {formik.touched.lastname && formik.errors.lastname}
              </div>
              
              <Custominput 
                type="email" 
                name="email" 
                placeholder="Email"
                onChange={formik.handleChange}
                value={formik.values.email}
                onBlur={formik.handleBlur}
              />
              <div className='error'>
                {formik.touched.email && formik.errors.email}
              </div>
              
              <Custominput 
                type="tel" 
                name="mobile" 
                placeholder="Mobile Number"
                onChange={formik.handleChange}
                value={formik.values.mobile}
                onBlur={formik.handleBlur}
              />
              <div className='error'>
                {formik.touched.mobile && formik.errors.mobile}
              </div>
              
              <Custominput 
                type="password" 
                name="password" 
                placeholder="Password"
                onChange={formik.handleChange}
                value={formik.values.password}
                onBlur={formik.handleBlur}
              />
              <div className='error'>
                {formik.touched.password && formik.errors.password}
              </div>

              
              <div className="d-flex justify-content-center align-items-center gap-15">
                <button className="butt-sign" type="submit">Sign Up</button>
              </div>

              <div className='cond-sign'>
                <p>By Signing up you agree to Kupto's</p>
                <p>
                <Link className='term-text' to="/terms-and-conditions">Terms and Conditions,</Link>
                </p>
                
                <Link className='term-text' to="/privacy-policy">Privacy Policy</Link>
              </div>
            </form>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Signup;